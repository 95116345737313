import { CardLimitApi } from '@blank/api'
import { useQueryWithAuth } from 'modules/auth/hooks/useQueryWithAuth'
import { QueryOptions } from 'modules/auth/types/queries'
import { fetchAccountCardLimits } from '../api/fetchAccountCardLimits'
import { EnumBankQueryKeys } from './keys'

interface Props {
  accountId?: string
  cardId?: string
  queryOptions?: QueryOptions<CardLimitApi[], Error>
}

export const useAccountCardLimitsQuery = ({
  accountId,
  cardId,
  queryOptions,
}: Props) => {
  return useQueryWithAuth<CardLimitApi[], Error>({
    queryKey: [EnumBankQueryKeys.ACCOUNT_CARD_LIMITS],
    queryFn: async (_context, accessToken) => {
      if (!accountId) {
        throw new Error(
          'useAccountCardLimitsQuery: Missing parameter accountId'
        )
      }

      if (!cardId) {
        throw new Error('useAccountCardLimitsQuery: Missing parameter cardId')
      }

      return fetchAccountCardLimits({
        accessToken,
        accountId,
        cardId,
      })
    },
    ...queryOptions,
    enabled: !!accountId && !!cardId && queryOptions?.enabled,
  })
}
