import { FetchAccountLimitsResponseApi } from '@blank/api'
import { apiClient } from 'modules/common/http/apiClient'
import { getAuthorizationHeader } from 'modules/common/lib/api/getAuthorizationHeader'

interface Props {
  accountId: string
  accessToken: string
}

export const fetchAccountLimits = async ({ accountId, accessToken }: Props) => {
  const { data } = await apiClient.get<FetchAccountLimitsResponseApi>(
    `/v1/bank/accounts/${accountId}/limits`,
    {
      headers: getAuthorizationHeader(accessToken),
    }
  )
  return data?.data
}
