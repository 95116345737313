import { FetchAccountCardLimitsResponseApi } from '@blank/api'
import { apiClient } from 'modules/common/http/apiClient'
import { getAuthorizationHeader } from 'modules/common/lib/api/getAuthorizationHeader'

interface Props {
  accountId: string
  cardId: string
  accessToken: string
}

export const fetchAccountCardLimits = async ({
  accountId,
  accessToken,
  cardId,
}: Props) => {
  const { data } = await apiClient.get<FetchAccountCardLimitsResponseApi>(
    `/v1/bank/accounts/${accountId}/cards/${cardId}/limits`,
    {
      headers: getAuthorizationHeader(accessToken),
    }
  )
  return data?.data
}
