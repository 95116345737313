import { CardApi } from '@blank/api'
import { useQueryClient } from '@tanstack/react-query'
import { useQueryWithAuth } from 'modules/auth/hooks/useQueryWithAuth'
import { QueryOptions } from 'modules/auth/types/queries'
import { fetchAccountCards } from '../api/fetchAccountCards'
import { EnumBankQueryKeys } from './keys'

interface Props {
  accountId: string | undefined
  queryOptions?: QueryOptions<CardApi[], Error>
  withCardDataConsistency?: boolean
}

export const useAccountCardsQuery = ({
  accountId,
  queryOptions,
  withCardDataConsistency,
}: Props) => {
  const queryClient = useQueryClient()

  return useQueryWithAuth<CardApi[], Error>({
    queryKey: [
      EnumBankQueryKeys.ACCOUNT_CARDS_LIST,
      ...(withCardDataConsistency ? [{ withCardDataConsistency }] : []),
    ],
    queryFn: async (_context, accessToken) => {
      if (!accountId) {
        throw new Error('useAccountCardsQuery: Missing parameter accountId')
      }

      return fetchAccountCards({
        accountId,
        accessToken,
        withCardDataConsistency,
        // Update inconsistent query cache with consistent data
        onFetchedWithConsistency: (data) => {
          queryClient.setQueryData([EnumBankQueryKeys.ACCOUNT_CARDS_LIST], data)
        },
      })
    },
    ...queryOptions,
    enabled: !!accountId,
  })
}
