import { useInternalAccount } from 'modules/bank/hooks/account/useInternalAccount'
import { useAccountCardsQuery } from 'modules/bank/queries/useAccountCardsQuery'
import { useCallback } from 'react'

interface Props {
  withCardDataConsistency?: boolean
}

export const useInternalAccountCards = ({
  withCardDataConsistency,
}: Props = {}) => {
  const {
    data: internalAccount,
    isError: isInternalAccountError,
    isLoading: isInternalAccountLoading,
    isSuccess: isInternalAccountSuccess,
    errorRefetch: refetchInternalAccount,
  } = useInternalAccount()

  const {
    data: cards,
    isError: isCardsError,
    isLoading: isCardsLoading,
    isSuccess: isCardsSuccess,
    refetch: refetchCards,
  } = useAccountCardsQuery({
    accountId: internalAccount?.id,
    withCardDataConsistency,
  })

  const errorRefetch = useCallback(() => {
    if (isInternalAccountError) {
      refetchInternalAccount()
    }
    if (isCardsError) {
      refetchCards()
    }
  }, [
    isInternalAccountError,
    isCardsError,
    refetchCards,
    refetchInternalAccount,
  ])

  return {
    data: { cards, internalAccount },
    isError: isCardsError || isInternalAccountError,
    isLoading: isInternalAccountLoading || isCardsLoading,
    isSuccess: isInternalAccountSuccess && isCardsSuccess,
    refetch: errorRefetch,
  }
}
