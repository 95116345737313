import { CardApi, FetchAccountCardsResponseApi } from '@blank/api'
import { apiClient } from 'modules/common/http/apiClient'
import { getAuthorizationHeader } from 'modules/common/lib/api/getAuthorizationHeader'

interface Props {
  accountId: string
  withCardDataConsistency?: boolean
  onFetchedWithConsistency?: (data: CardApi[]) => void
  accessToken: string
}

export const fetchAccountCards = async ({
  accountId,
  withCardDataConsistency,
  onFetchedWithConsistency,
  accessToken,
}: Props) => {
  const apiUrl = `/v1/bank/accounts/${accountId}/cards`

  const {
    data: { data },
  } = await apiClient.get<FetchAccountCardsResponseApi>(apiUrl, {
    headers: getAuthorizationHeader(accessToken),
    params: {
      ...(withCardDataConsistency ? { consistency: 'true' } : {}),
    },
  })

  if (withCardDataConsistency) {
    onFetchedWithConsistency?.(data)
  }

  return data
}
