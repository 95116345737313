import { AccountLimitApi } from '@blank/api'
import { useQueryWithAuth } from 'modules/auth/hooks/useQueryWithAuth'
import { QueryOptions } from 'modules/auth/types/queries'
import { fetchAccountLimits } from '../api/fetchAccountLimits'
import { EnumBankQueryKeys } from './keys'

export const useAccountLimitsQuery = (
  accountId?: string,
  queryOptions?: QueryOptions<AccountLimitApi[], Error>
) => {
  return useQueryWithAuth<AccountLimitApi[], Error>({
    queryKey: [EnumBankQueryKeys.ACCOUNT_LIMITS],
    queryFn: async (_context, accessToken) => {
      if (!accountId) {
        throw new Error('useAccountLimitsQuery: Missing parameter accountId')
      }

      return fetchAccountLimits({
        accessToken,
        accountId,
      })
    },
    ...queryOptions,
    enabled: !!accountId,
  })
}
