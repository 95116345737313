import { CardApi } from '@blank/api'
import { EnumCardStatusPriority } from '../../types'
import { useInternalAccountCards } from './useInternalAccountCards'

interface Props {
  withCardDataConsistency?: boolean
}

export const useCurrentCard = ({ withCardDataConsistency }: Props = {}) => {
  const {
    data: { cards, internalAccount },
    ...rest
  } = useInternalAccountCards({ withCardDataConsistency })
  // Sort card by status priority ACT->BLO->PRE->CAN->Others
  const sortedCards = cards?.sort((a: CardApi, b: CardApi) => {
    if (
      EnumCardStatusPriority[a.statusCode] &&
      EnumCardStatusPriority[b.statusCode]
    ) {
      return (
        EnumCardStatusPriority[a.statusCode] -
        EnumCardStatusPriority[b.statusCode]
      )
    }
    return 1000
  })

  return {
    data: { currentCard: sortedCards?.[0], internalAccount },
    ...rest,
  }
}
